@charset "UTF-8";
/* webfont 変数　*/
/* common-title
-----------------------------------------------------*/
/* ofi */
@media (min-width: 1920px) {
  /*====================================================================/
/*--Large PC 以上--
/====================================================================*/
}

@media (min-width: 1536px) {
  /*====================================================================/
/*---PC 以上--
/====================================================================*/
}

@media (min-width: 1024px) {
  /*====================================================================/
/*--XGAサイズ　iPadPro 以上--
/====================================================================*/
}

@media (min-width: 768px) {
  /*====================================================================/
/*--タブレット　iPadmini 以上--
/====================================================================*/
}

@media (min-width: 576px) {
  /*====================================================================/
/*--スマホ 以上--
/====================================================================*/
}

@media (min-width: 376px) {
  /*====================================================================/
/*--iPhone 以上--
/====================================================================*/
}

@media (max-width: 1919px) {
  /*====================================================================/
/*--Large PC 未満--
/====================================================================*/
}

@media (max-width: 1535px) {
  /*====================================================================/
/*--PC 未満--
/====================================================================*/
}

@media (max-width: 1023px) {
  /*====================================================================/
/*--XGAサイズ iPadPro 未満--
/====================================================================*/
}

@media (max-width: 767px) {
  /*====================================================================/
/*--タブレット iPadmini 未満--
/====================================================================*/
}

@media (max-width: 575px) {
  /*====================================================================/
/*--iPhone 以下--
/====================================================================*/
}

@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
/*--スマホ以上 タブレット iPadmini未満--
/====================================================================*/
}

@media (min-width: 768px) and (max-width: 1023px) {
  /*====================================================================/
/*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
/====================================================================*/
}

@media (min-width: 1024px) and (max-width: 1535px) {
  /*====================================================================/
/*--XGAサイズ iPadPro以上 PC未満--
/====================================================================*/
}

@media (min-width: 1536px) and (max-width: 1919px) {
  /*====================================================================/
/*--px以上 Large PC未満--
/====================================================================*/
}

/* CSS Document */
/*====================================================================

	scroll animation

=====================================================================*/
/* fade-in */
.fade-in.trigger--off,
.trigger--off .fade-in {
  opacity: 0;
}

.fade-in.trigger--on,
.trigger--on .fade-in {
  transition: all 0.8s cubic-bezier(0.34, 0.09, 0.98, 0.83);
  opacity: 1;
}

/* fade-up */
.fade-up.trigger--off,
.trigger--off .fade-up {
  opacity: 0;
  transform: translateY(100px);
}

.fade-up.trigger--on,
.trigger--on .fade-up {
  transition: all 0.8s cubic-bezier(0.38, 0.1, 0.72, 0.96);
  opacity: 1;
  transform: translateY(0);
}
